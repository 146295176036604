import {observer} from 'mobx-react'
import { t } from 'ttag'
import autobind from 'autobind-decorator'
import scrollIntoView from 'scroll-into-view-if-needed'
import * as React from 'react'
import {
    Form, FormGroup, Label, Input, Button, InputGroup, DropdownToggle, Dropdown,
    DropdownMenu, DropdownItem, ListGroup, ListGroupItem, Col, Alert,
} from 'reactstrap'
import {getStateInstance, RefFieldState, REQUIRE_SUBMITTER_ADDRESS} from './state'
import {FieldFeedBack} from '../Forms/FieldFeedBack'
import { navigate, Link } from '../../util/navigate'
import CompEstimate from './CompEstimate'
import { PHONE_PREFIXES } from './CountriesAndPhonePrefixes'
import './SubmitterContactDetailsForm.scss'
import {getLanguageCode} from '../../locales'
import CountryAutocomplete from '../BankDetailsForm/CountryAutocomplete'


interface Props {
    location: Location
}


@observer
export class SubmitterContactDetailsForm extends React.Component<Props> {

    state = {
        isOpen: false,
        searchText: '',
        selectedPhonePrefix: '+371',
    }

    onToggle() {
        this.setState({isOpen: !this.state.isOpen})
    }

    onDropdownItemClick(selectedPhonePrefix) {
        this.setState({
            selectedPhonePrefix: selectedPhonePrefix,
            searchText: '',
        })
    }

    onSearchInputChange(searchText) {
        this.setState({
            searchText: searchText,
        })
    }

    displayDropdownItem(phonePrefix) {
        let display = "block"
        let prefix = phonePrefix.prefix.replace(' ', '')
        let name = phonePrefix.name.replace(' ', '').toLowerCase()
        let searchText = this.state.searchText.replace(' ', '')
        
        if (searchText) {
            if(searchText.match(/^[A-Za-z]+$/)){
                if (name.indexOf(searchText.toLowerCase()) == -1) {
                    display = "none"
                }
            } else if (prefix.indexOf(searchText) == -1) {
                display = "none"
            }
        }
        
        return display
    }
    
    render () {
        let pageState = getStateInstance()
        let formState = pageState.formState.submitterContact
        let fields = formState.$

        fields.phonePrefix.onChange(this.state.selectedPhonePrefix)

        return <Form onSubmit={this.onSubmit}>
            <h1>{t`Your compensation estimate`}</h1>

            <CompEstimate amount={pageState.amountPerPassenger}/>

            <p>
                {t`We need some basic information about you to begin processing your claim:`}
            </p>

            <FormGroup>
                <a ref={fields.email.positionRef as any}/>
                <Label for="email">{t`E-mail address`}</Label>
                <Input
                    type="email"
                    name="email"
                    invalid={!!fields.email.error}
                    value={fields.email.value || ''}
                    onChange={(e) => fields.email.onChange(e.target.value)}
                    onBlur={() => fields.email.validate()}
                />
                <FieldFeedBack field={fields.email}/>
            </FormGroup>

            <FormGroup>
                <a ref={fields.name.positionRef as any}/>
                <Label for="name">{t`Full name`}</Label>
                <Input
                    type="text"
                    name="name"
                    invalid={!!fields.name.error}
                    value={fields.name.value || ''}
                    onChange={(e) => fields.name.onChange(e.target.value)}
                    onBlur={() => fields.name.validate()}
                />
                <FieldFeedBack field={fields.name}/>
            </FormGroup>

            <FormGroup>
                <a ref={fields.phoneNumber.positionRef as any}/>
                <Label for="email">{t`Phone number (optional)`}</Label>

                <InputGroup>
                    <Dropdown
                        id="phone-country-code-dropdown"
                        isOpen={this.state.isOpen}
                        toggle={() => this.onToggle()}
                    >
                        <DropdownToggle color="secondary" caret>
                            {this.state.selectedPhonePrefix}
                        </DropdownToggle>
                        <DropdownMenu>
                            <ListGroup>
                                <ListGroupItem>
                                    <Input
                                        type="text"
                                        placeholder="Search"
                                        value={this.state.searchText}
                                        onChange={(e) => this.onSearchInputChange(e.target.value)}
                                    />
                                </ListGroupItem>
                                <ListGroupItem>
                                    {PHONE_PREFIXES.map(i => {
                                        return (
                                            <DropdownItem
                                                style={{display: this.displayDropdownItem(i)}}
                                                onClick={() => this.onDropdownItemClick(i.prefix)}
                                                key={i.name}
                                            >
                                                <span className={`flag-icon flag-icon-${i.code}`}>
                                                </span>
                                                {" "}{i.prefix}
                                                {" "}{i.name}
                                            </DropdownItem>
                                        )
                                    })}
                                </ListGroupItem>
                            </ListGroup>
                        </DropdownMenu>
                    </Dropdown>
                    <Input
                        type="text"
                        name="phoneNumber"
                        invalid={!!fields.phoneNumber.error}
                        value={fields.phoneNumber.value || ''}
                        onChange={(e) => fields.phoneNumber.onChange(e.target.value)}
                        onBlur={() => fields.phoneNumber.validate()}
                    />
                </InputGroup>

                <FieldFeedBack field={fields.phoneNumber}/>
            </FormGroup>


            {REQUIRE_SUBMITTER_ADDRESS ? this.renderAddressFields() : null}

            <FormGroup className="allow-process-personal-data">
                <Label check>
                    <Input
                        type="checkbox"
                        onChange={(ev) => {
                            fields.allowProcessPersonalData.onChange(ev.target.checked)
                        }}
                        invalid={!!fields.allowProcessPersonalData.error}
                    />
                    {' '} {t`I agree to provide permission to process my personal data to Refundor.
                    Read more on`} <Link to="/privacy-policy/">{t`Privacy Policy page`}</Link>.
                    <FieldFeedBack field={fields.allowProcessPersonalData}/>
                </Label>
            </FormGroup>

            <Alert color="primary">
                {t`First check done! Your flight falls under European flight compensation law.`}
            </Alert>

            <Button
                className="submit btn-with-transition"
                block
                color="primary"
                // disabled={!fields.allowProcessPersonalData.$}
            >
                {t`Continue`}
            </Button>

        </Form>
    }

    private renderAddressFields() {
        let pageState = getStateInstance()
        let formState = pageState.formState.submitterContact
        let fields = formState.$

        return <>
            <FormGroup>
                <a ref={fields.address1.positionRef as any}/>
                <Label for="address1">{t`Address`}</Label>
                <Input
                    type="text"
                    name="address1"
                    invalid={!!fields.address1.error}
                    value={fields.address1.value || ''}
                    onChange={(e) => fields.address1.onChange(e.target.value)}
                    onBlur={() => fields.address1.validate()}
                />
                <FieldFeedBack field={fields.address1}/>
            </FormGroup>

            <FormGroup>
                <a ref={fields.address2.positionRef as any}/>
                <Label for="address2">{t`Address line 2 (optional)`}</Label>
                <Input
                    type="text"
                    name="address2"
                    invalid={!!fields.address2.error}
                    value={fields.address2.value || ''}
                    onChange={(e) => fields.address2.onChange(e.target.value)}
                    onBlur={() => fields.address2.validate()}
                />
                <FieldFeedBack field={fields.address2}/>
            </FormGroup>

            <FormGroup row>
                <Col sm={6}>
                    <a ref={fields.city.positionRef as any}/>
                    <Label for="city">{t`City`}</Label>
                    <Input
                        type="text"
                        name="city"
                        invalid={!!fields.city.error}
                        value={fields.city.value || ''}
                        onChange={(e) => fields.city.onChange(e.target.value)}
                        onBlur={() => fields.city.validate()}
                    />
                    <FieldFeedBack field={fields.city}/>
                </Col>
                <Col sm={6}>
                    <a ref={fields.postal_code.positionRef as any}/>
                    <Label for="postal_code">{t`Postal code`}</Label>
                    <Input
                        type="text"
                        name="postal_code"
                        invalid={!!fields.postal_code.error}
                        value={fields.postal_code.value || ''}
                        onChange={(e) => {
                            const digitsOnly = e.target.value.replace(/\D/g, "");
                            fields.postal_code.onChange(digitsOnly);
                        }}
                        onBlur={() => fields.postal_code.validate()}
                    />
                    <FieldFeedBack field={fields.postal_code}/>
                </Col>
            </FormGroup>

            <FormGroup>
                <a ref={fields.state.positionRef as any}/>
                <Label for="state">{t`State (optional)`}</Label>
                <Input
                    type="text"
                    name="state"
                    invalid={!!fields.state.error}
                    value={fields.state.value || ''}
                    onChange={(e) => fields.state.onChange(e.target.value)}
                    onBlur={() => fields.state.validate()}
                />
                <FieldFeedBack field={fields.state}/>
            </FormGroup>

            <FormGroup>
                <a ref={fields.country.positionRef as any}/>
                <Label for="country">{t`Country`}</Label>
                <CountryAutocomplete
                    id="country"
                    name="country"
                    onChange={(value) => fields.country.onChange(value[0].name)}
                    onFocus={() => fields.country.setError(null)}
                    onBlur={() => fields.country.validate()}
                />
                <FieldFeedBack field={fields.country}/>
            </FormGroup>
        </>;
    }

    @autobind
    async onSubmit (e) {
        e.preventDefault()

        let pageState = getStateInstance()
        let validationResult = await pageState.formState.submitterContact.validate()

        if (!validationResult.hasError) {
            navigate('/claim-form/booking-details', getLanguageCode(this.props.location))
            pageState.formState.submitterContact.$.allowProcessPersonalData.$ &&
            await pageState.submitPartialForm()
        }

        let fields = pageState.formState.submitterContact.$
        let fieldOrder: RefFieldState<any>[] = [
            fields.email,
            fields.name,
            fields.phoneNumber,
        ]

        // Scroll to first invalid element
        for (let field of fieldOrder) {
            if (field.hasError && field.positionRef.current) {
                scrollIntoView(
                    field.positionRef.current as any,
                    {behavior: 'smooth', block: 'start', inline: 'start'}
                )

                break
            }
        }
    }
}
