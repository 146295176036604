import * as React from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { PhonePrefix, PHONE_PREFIXES } from '../ClaimForm/CountriesAndPhonePrefixes';

export interface OnChangeFunc {
    (value: PhonePrefix[]): void
}


interface CountryAutocompleteProps {
    id?: string
    name?: string
    placeholder?: string
    onChange: OnChangeFunc
    onBlur?: Function | null
    onFocus?: Function | null
    defaultValue?: string
    selected?: string
}


export default
class CountryAutocomplete extends React.Component <CountryAutocompleteProps> {
    state = {
        requestCount: 0,
        countries: [],
        defaultValue: null,
    }

    componentDidMount () {
        if (this.props.defaultValue) {
            this.loadDefault()
        }
    }

    async loadDefault () {
        let data = PHONE_PREFIXES
        this.setState({defaultValue: data})
    }

    getLabel = (item) => {
        return `${item.name}`
    }

    onSearch = async (query) => {
        this.loadCountries(query)
    }

    async loadCountries (query) {
        this.setState({
            requestCount: this.state.requestCount + 1
        })

        query = query.replace(' ', '')

        let result = PHONE_PREFIXES.filter(item => {
            let countryName = item.name.replace(' ', '').toLowerCase()

            return countryName.indexOf(query.toLowerCase()) != -1
        })

        this.setState({requestCount: this.state.requestCount - 1, countries: result})

        return result
    }

    renderMenuItemChildren (option, props, index) {
        return <React.Fragment key={option.code}>
            <div>{option.name}</div>
        </React.Fragment>
    }

    render () {
        if (this.props.defaultValue && !this.state.defaultValue) {
            return null
        }

        return <AsyncTypeahead
            id={this.props.id}
            name={this.props.name}
            options={this.state.countries}
            labelKey={this.getLabel}
            onSearch={this.onSearch}
            isLoading={this.state.requestCount > 0}
            minLength={2}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            placeholder={this.props.placeholder}
            defaultSelected={this.state.defaultValue || []}
            selected={this.props.selected ? [this.props.selected] : null}
            renderMenuItemChildren={this.renderMenuItemChildren}
        />
    }
}
