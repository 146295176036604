import * as React from 'react'
import { t } from 'ttag'

import './CompEstimate.scss'


export default class CompEstimate extends React.Component<{amount: number}> {
    render() {
        return <div className="comp-estimate">
            <div className="d-block d-md-inline text-center">{t`You can receive up to`}</div>
            <div>
                <span className="amount d-block d-md-inline text-center">€{this.props.amount} </span>
                <span className="per-passenger d-block d-md-inline  text-center">{t`compensation per passenger`}</span>
            </div>
        </div>
    }
}
